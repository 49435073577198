<template>
	<v-container fluid>

    <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" > 
      <strong> {{alerta.texto}} </strong> 
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
      </template>
    </v-snackbar>

    <v-toolbar flat>
      <div class="font-weight-black text-md-h6 text-xs-subtitle-1 "> DETALLE DEL PEDIDO  </div>
      <v-spacer></v-spacer>
      <v-btn color="error"  @click="$emit('modal',false)" ><v-icon>clear</v-icon></v-btn>
    </v-toolbar>

    <v-container style="height: 400px;" v-if="Loading">
      <loading/>
    </v-container>

		<v-row v-if="!Loading">
      <!-- DIRECCION ENVIO -->
      <v-col cols="12" sm="6"  class=" mt-1">
        <v-card class="shadowCard">
           <v-list-item >
            <v-list-item-content >
              <v-list-item-title class="font-weight-black subtitle-1"> Dirección de envío</v-list-item-title>
              <v-divider></v-divider>
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Nombre:  </span> 
               <span class="caption"> {{ envio.nombre }} {{ envio.apellido }}  </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Municipio: </span>  
               <span class="caption"> {{ envio.municipio }}  </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Colonia:  </span>  
               <span class="caption"> {{ envio.colonia }} </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Calle: </span>  
               <span class="caption"> {{ envio.calle }} {{ envio.numero }}, {{ envio.cp }} </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Telefono: </span>  
               <span class="caption"> {{ envio.telefono }}  </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Correo electronico: </span> 
               <span class="caption"> {{ envio.email}}  </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card> 
      </v-col>
      
      <!-- DIRECCION FACTURACIÓN -->
      <v-col cols="12" sm="6"  class="mt-1" v-if="facturacion">
        <v-card class="shadowCard" >
           <v-list-item >
            <v-list-item-content >
              <v-list-item-title class="font-weight-black subtitle-1"> Dirección de facturación</v-list-item-title>
              <v-divider></v-divider>
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Nombre:  </span> 
               <span class="caption"> {{facturacion.nombre }} {{facturacion.apellido }}  </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Municipio: </span>  
               <span class="caption"> {{facturacion.municipio }}  </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Colonia:  </span>  
               <span class="caption"> {{facturacion.colonia }} </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Calle: </span>  
               <span class="caption"> {{ envio.calle }} {{ envio.numero }}, {{ envio.cp }} </span>
              </v-list-item-subtitle>
              
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Telefono: </span>  
               <span class="caption"> {{facturacion.telefono }}  </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black caption" v-if="$vuetify.breakpoint.name != 'xs'">  Correo electronico: </span> 
               <span class="caption"> {{facturacion.email}}  </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card> 
      </v-col>
      
      <!-- PRODUCTOS DE LA LISTA -->
			<v-col cols="12" >
        <v-card  class="shadowCard"  v-for="(item, i) in productos" :key="i">
           <v-list-item  >
            <v-list-item-avatar :size="$vuetify.breakpoint.name === 'xs'? 40:130  " tile>
              <v-img :src="url + item.foto" v-if="item.foto" loading="lazy"></v-img>
              <v-img :src="`${url + photo}`" loading="lazy" v-else/>
            </v-list-item-avatar>
            <v-list-item-content >
              <v-list-item-subtitle class="pb-0 text-xs-caption text-sm-subtitle-1">
               <span class="font-weight-black"  v-if="$vuetify.breakpoint.name != 'xs'">  Codigo: </span> {{ item.codigo }}  
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-xs-caption text-sm-subtitle-1">
               <span class="font-weight-black " v-if="$vuetify.breakpoint.name != 'xs'" >  Producto : </span>  {{ item.nomart}}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-xs-caption text-sm-subtitle-1">
                <span class="font-weight-black " v-if="$vuetify.breakpoint.name != 'xs'" >  Cantidad:  </span> {{ item.cantidad }} 
              </v-list-item-subtitle>
              <!--
              <v-list-item-subtitle class="text-xs-caption text-sm-subtitle-1">
                <span class="font-weight-black " v-if="$vuetify.breakpoint.name != 'xs'" >  Descuento:  </span>  {{ item.pjedesc }}%
              </v-list-item-subtitle> -->
              <v-list-item-subtitle class="text-xs-caption text-sm-subtitle-1">
                <span class="font-weight-black " v-if="$vuetify.breakpoint.name != 'xs'" > Precio:  </span>  
                {{ item.precio1 - (item.pjedesc / 100) * item.precio1  | currency(2) }} MX.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card> 
      </v-col>
      <!-- 
      <v-col cols="12" sm="5" md="6" class="">
        <v-btn text color="secondary" target="_blank" :href="parametros.rastreo[0].trackUrl" > 
          Quiero rastrear mi pedido.  <v-icon class="text-decoration-underline mx-3"right large> mdi-truck-fast-outline </v-icon>
        </v-btn>
      </v-col>
      -->
      <!-- TOTALES -->
     <v-col cols="12" sm="7" md="6" offset-md="6" offset-sm="5" class="py-0">
          <v-card class="shadowCard  mt-2" >
           <!-- <v-card-actions>
              <v-card-text class="font-weight-black "> TOTAL DEL CARRITO </v-card-text>
            </v-card-actions> -->
            <v-list dense >
              <v-list-item  class="py-0">
                <v-list-item-content>
                  <v-list-item-title > Subtotal</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content class="text-right">
                  <v-list-item-title > {{ parametros.subtotal | currency(2) }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item  class="py-0">
                <v-list-item-content>
                  <v-list-item-title > Envío </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content class="text-right" >
                  <v-list-item-title v-if="parametros.envio_gratis"> 
                    <v-btn text small color="success" class="font-weight-black"> Envío Gratis </v-btn>
                  </v-list-item-title>
                  <v-list-item-title v-else> 
                    {{ parametros.envio | currency(2) }} 
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item  class="py-0" v-if="parametros.descuento_cupon != 0">
                <v-list-item-content>
                  <v-list-item-title > Cupon promocional </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content class="text-right">
                  <v-list-item-title > {{ parametros.descuento_cupon | currency(2) }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

               <v-list-item  class="py-0">
                <v-list-item-content>
                  <v-list-item-title > Total </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content class="text-right">
                  <v-list-item-title > {{ parametros.total | currency(2) }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  // import  metodos from '@/mixins/metodos.js';
	import {mapGetters, mapActions} from 'vuex'
  import loading        from '@/components/loading.vue'
  import Vue from 'vue'
	var accounting = require("accounting");
  Vue.filter('currency', (val, dec) => { return accounting.formatMoney(val, '$', dec) });

  export default {
    // mixins:[metodos],
	  components: {
			loading,
		},
		props:[
			'parametros',
	  ],

    data(){
      return{
        productos:[],
        envio:[],
        facturacion:[],
        
        Loading: false,
        url   : this.$http.options.root + 'fotos-fetish/' ,
        photo : 'nophoto.png',
        ObjectDefault:{},
        alerta: { activo: false, texto:'', color:'error', vertical:true },
      }
    },

    created(){
				this.initialize();
		},

    watch:{
			parametros(){
				this.initialize();
			},
    },
			
		computed:{
			// IMPORTANDO USO DE VUEX - CLIENTES (GETTERS)
      ...mapGetters('login',['getusuarioFetish']),
		},

    methods:{

      initialize(){

        this.Loading = true;
        this.envio       = this.parametros.direcciones[0];
        this.facturacion = this.parametros.facturacion[0];
        this.productos   = this.parametros.movim;
        setTimeout(()=> {this.Loading = false }, 500); 

        //  this.Loading = true;
        // //  CONSULTAR DIRECCION 
        // this.$http.get('obtener.direccion.envio.docum/' + this.parametros.iddirecion).then( response =>{
        //   this.envio = response.body[0];
        // });
        // //  CONSULTAR FACTURACION
        // this.$http.get('obtener.direccion.facturacion.docum/' + this.parametros.iddireccionfacturacion).then( response =>{
        //   this.facturacion = response.body[0];
        // });
        // //  CONSULTAR PRODUCUCTOS SOLICITADOS
        // this.$http.get('obtener.productos.movim/' + this.parametros.iddocum).then( response =>{
        //   this.productos =  response.body;
        // }).finally(()=>{
        //   this.Loading = false;
        // })
      },
    }



  }
</script>