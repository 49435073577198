var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-snackbar',{attrs:{"multi-line":"","vertical":"","top":"","right":"","color":_vm.alerta.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.alerta.activo = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.alerta.activo),callback:function ($$v) {_vm.$set(_vm.alerta, "activo", $$v)},expression:"alerta.activo"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.alerta.texto)+" ")])]),_c('v-btn',{attrs:{"fab":"","fixed":"","bottom":"","left":"","color":"primary","onClick":"history.go(-1);"}},[_c('v-icon',[_vm._v("mdi-arrow-left-thick")])],1),_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-1 shadowCard"},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"8"}},[_c('v-card-title',[_vm._v(" PEDIDOS REALIZADOS ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar pedido ","placeholder":"Escriba el dato que desea buscar","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pedidos,"search":_vm.search,"height":_vm.$vuetify.breakpoint.height < '550'? '400' :_vm.tamanioPantalla,"fixed-header":"","hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"loading":_vm.Loading},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.fecha).format('llll'))+" ")]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.subtotal,2))+" ")]}},{key:"item.envio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.envio,2))+" ")]}},{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [_vm._v(" % "+_vm._s(item.descuento ? item.descuento : 0)+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total,2))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":"","dark":""},on:{"click":function($event){_vm.modalPedido = true; _vm.parametros = item}}},[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}])})],1),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.modalPedido),callback:function ($$v) {_vm.modalPedido=$$v},expression:"modalPedido"}},[_c('v-card',{staticClass:"pa-4"},[_c('controlPedidos',{attrs:{"parametros":_vm.parametros},on:{"modal":function($event){_vm.modalPedido = $event}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }