<template>
  <v-container>
    <v-row justify="center">

      <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" > 
        <strong> {{alerta.texto}} </strong>
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
        </template>
      </v-snackbar>
      <v-btn fab fixed bottom left  color="primary" onClick="history.go(-1);"  > <v-icon >mdi-arrow-left-thick</v-icon> </v-btn>

      <v-col cols="12" class="mt-1">
          <v-card  class="pa-1 shadowCard">
            <v-row class="mt-1">
              <v-col cols="12" sm="8" class="py-0">
                <v-card-title> PEDIDOS REALIZADOS  </v-card-title>
              </v-col>

              <v-col cols="12" sm="4" class="">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar pedido "
                  placeholder="Escriba el dato que desea buscar"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
                <!-- <v-btn @click="initialize()">A</v-btn> -->
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="pedidos"
              :search="search"
              :height="$vuetify.breakpoint.height < '550'? '400' :tamanioPantalla"
              fixed-header
              hide-default-footer
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              :loading ="Loading"
            >
              <template v-slot:item.fecha="{ item }">
                {{ moment(item.fecha).format('llll') }}
              </template>

              <template v-slot:item.subtotal="{ item }">
                {{ item.subtotal | currency(2) }}
              </template>
              <template v-slot:item.envio="{ item }">
                {{ item.envio | currency(2) }}
              </template>
              <template v-slot:item.descuento="{ item }">
                % {{ item.descuento ? item.descuento : 0 }}
              </template>
              <template v-slot:item.total="{ item }">
                {{ item.total | currency(2) }}
              </template>
 
            	<template v-slot:item.actions="{ item }" > 
				    		<v-btn  color="primary" class="ma-1"  small dark  @click="modalPedido = true; parametros = item">
									<v-icon> mdi-eye </v-icon>
								</v-btn> 
				    	</template>

            </v-data-table>
          </v-card>
          
        <!-- PAGINACION -->
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
      </v-col>

      <v-dialog v-model="modalPedido" width="800">
        <v-card class="pa-4" >
          <controlPedidos 
            :parametros="parametros"
            @modal="modalPedido = $event" />
        </v-card>
      </v-dialog>

    </v-row>
  </v-container>
</template>

<script>
  import  metodos from '@/mixins/metodos.js';
  import controlPedidos from '@/views/Cliente/control_pedidos.vue'
	import {mapGetters, mapActions} from 'vuex'

  import Vue from 'vue'
	var accounting = require("accounting");
  var moment     = require('moment'); moment.locale('es') /// inciar Moment 
  Vue.filter('currency', (val, dec) => { return accounting.formatMoney(val, '$', dec) });
  
  export default {
    mixins:[metodos],
    components: {
			controlPedidos,
		},
    data: () => ({
      page: 0,
      pageCount: 0,
      itemsPerPage: 100,
      search:'',
      pedidos:[],
      headers: [
        // { text: 'Pedido'          , align: 'start'  , sortable: false, value: 'iddocum'    },
        { text: 'Fecha de compra' , align: 'left'   , sortable: false, value: 'fecha'      },
        { text: 'Subtotal'        , align: 'right'  , sortable: false, value: 'subtotal'   },
        { text: 'Envio'           , align: 'right'  , sortable: false, value: 'envio'      },
        { text: 'Descuento'       , align: 'center' , sortable: false, value: 'descuento'  },
        { text: 'Total'           , align: 'right'  , sortable: false, value: 'total'      },
        { text: ''                , align: 'right'  , sortable: false, value: 'actions'    },
      ],

      alerta: { activo: false, texto:'', color:'error' },
      Loading: false,

      parametros: {},
      modalPedido: false
    }),

    computed: {
      ...mapGetters('login',['getusuarioFetish']),
      
      tamanioPantalla () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return this.$vuetify.breakpoint.height -300;
          case 'sm': return this.$vuetify.breakpoint.height -300;
          case 'md': return this.$vuetify.breakpoint.height -300;
          case 'lg': return this.$vuetify.breakpoint.height -300;
          case 'xl': return this.$vuetify.breakpoint.height -300;
          
        }
      },
      
    },

    watch: {
    },

    created () {
      this.initialize();
      	// this.$router.push({name:'verpedido',params:{docum: pedido}})

    },

    methods: {
      initialize () {
        this.Loading = true;
        this.$http.get('ordenes.list/' + this.getusuarioFetish.idcliente).then( response =>{
          console.log(response)
          this.pedidos = response.body
        }).catch( error =>{
          console.log('error', error)
        }).finally(()=>{ this.Loading = false})
      },
    },
  }
</script>